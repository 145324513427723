<template>
  <!-- <section class="social-login" v-if="show"> -->
  <section class="social-login">
    <div class="social-login__o">
      <span v-if="showLabel" :class="color">o</span>
      <span v-else :class="color">{{ $t("login.orContinueWith", lang) }}</span>
    </div>
    <div :class="['social-login__items', { 'social-login__items-force-vertical': verticalAlign, '-force-vertical': verticalAlign }]">
      <a
        v-if="showGoogle"
        class="social-login__item"
        :class="[
          showLabel ? 'social-login__item--label' : '',
          size ? 'social-login__item--large' : '',
        ]"
        @click.prevent="loginWithGoogle()"
        :href="authUrl"
      >
        <img
          src="../../../assets/img/ico/ico-login-rounded-google.svg"
          alt="Login con Google"
        />
        <span v-if="showLabel">{{ $t("login.googleLogin", lang) }}</span>
      </a>
      <button
        v-if="showFacebook"
        class="social-login__item"
        :class="[
          showLabel ? 'social-login__item--label' : '',
          size ? 'social-login__item--large' : '',
        ]"
      >
        <img
          src="../../../assets/img/ico/ico-login-rounded-facebook.svg"
          alt="Login con Facebook"
        />
        <span v-if="showLabel">Entrar con Facebook</span>
      </button>
      <button
        v-if="showApple"
        class="social-login__item"
        :class="[
          showLabel ? 'social-login__item--label' : '',
          size ? 'social-login__item--large' : '',
        ]"
        @click.prevent="loginWithApple()"
      >
        <img
          src="../../../assets/img/ico/ico-login-rounded-apple.svg"
          alt="Login con Apple ID"
        />
        <span v-if="showLabel">{{ $t("login.appleLogin", lang) }}</span>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "LoginSocial",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showGoogle: {
      type: Boolean,
      default: true,
    },
    showFacebook: {
      type: Boolean,
      default: true,
    },
    showApple: {
      type: Boolean,
      default: true,
    },
    origin: {
      type: String,
      default: "signup",
    },
    color: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: false,
    },
    referralUserId: {
      type: String,
      default: "",
    },
    beforeCallback: {
      type: Function,
      default: (next) => {
        return next();
      },
    },
    forcedLang: {
      type: String,
      default: "",
    },
    wantedRoute: {
      type: String,
      default: "",
    },
    retailerName: {
      type: String,
      default: "",
    },
    verticalAlign: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "",
    },
  },
  computed: {
    isModal() {
      return this.$route.query.modal === "1";
    },
    isLimitedBrowser() {
      return this.$route.query.mode === "limited";
    },
    isIframe() {
      return this.origin === "plugin";
    },
    authUrl() {
      return `/auth/google?${this.getRawQueryParams()}`;
    },
    authUrlApple() {
      return `/auth/apple?${this.getRawQueryParams(true)}`;
    },
    lang() {
      return this.forcedLang || this.$i18n.locale;
    },
  },
  methods: {
    async sleep(ms = 0) {
      return new Promise((resolve) => {
        return setTimeout(() => {
          this.$nextTick(() => {
            resolve();
          });
        }, ms);
      });
    },
    async waitUntilClosed(w) {
      while (true) {
        if (w.closed) {
          return true;
        }

        await this.sleep(1_000);
      }
    },
    async openPopup(url) {
      await this.$store.dispatch("clearSiteData");

      const width = 800;

      // The 860 here is because if it's a new user, the onboarding will happen
      // inside the popup, and 860 looks like a good height for that.
      //
      // If someday the onboarding is removed from the popup, then a height of
      // 480 looks nice enough for Google Login.
      const height = 860;

      let windowFeatures = "popup";
      if (width && height) {
        windowFeatures += `,width=${width},height=${height}`;
      }

      console.log(`Opening popup: ${url}`);
      console.log(`Window features: ${windowFeatures}`);

      const w = window.open(url, "_blank", windowFeatures);
      this.$emit("popup");
      await this.waitUntilClosed(w);
    },
    loginWithGoogle() {
      return this.beforeCallback(() => {
        if (!this.isModal || this.isLimitedBrowser) {
          window.location.href = this.authUrl;

          return;
        }

        return this.openPopup(this.authUrl).then(() => {
          this.$emit("loggedIn");
        });
      });
    },
    loginWithApple() {
      return this.beforeCallback(() => {
        if (this.isLimitedBrowser || (!this.isModal && !this.$route.meta?.iframe)) {
          window.location.href = this.authUrlApple;

          return;
        }

        return this.openPopup(this.authUrlApple).then(() => {
          this.$emit("loggedIn");
        });
      });
    },
    getQueryParams(ignoreRedirect = false) {
      const queryParams = {
        origin: this.origin,
      };

      if (this.referralUserId) {
        queryParams.referralUserId = this.referralUserId;
      }
      if (this.isModal) {
        queryParams.modal = "1";
      }
      if (this.isIframe) {
        queryParams.iframe = "1";
      }
      if (this.wantedRoute && !ignoreRedirect) {
        queryParams.wantedRoute = this.wantedRoute;
      }
      if (this.retailerName) {
        queryParams.retailer = this.retailerName;
      }
      if (this.listType) {
        queryParams.listType = this.listType;
      }

      if (this.isLimitedBrowser) {
        queryParams.wantedRoute = window.location.pathname + window.location.search;
      }

      let source_url = ''
      try {
        source_url = localStorage.getItem('source_url');
        console.log('source_url', source_url);
      }
      catch (e) {
        console.log(e);
      }
      if (source_url) {
        queryParams.source_url = source_url;
      }

      console.log('queryParams', queryParams);

      return queryParams;
    },
    getRawQueryParams(ignoreRedirect = false) {
      // This computed value only exists because all auth URLs share the same
      // query params.
      //
      // If they ever stop being the same, it might be better to remove all
      // this to a `toQueryString` method that receives query params as object.

      return Object.entries(this.getQueryParams(ignoreRedirect))
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
    },
  },
};
</script>

<style lang="scss" scoped></style>
